<td class="w-8 h-8" colspan="1" [ngClass]="{'bg-gray-50': lessRelevant()}">
    <div class="bg-gray-100 rounded-full w-8 h-8 overflow-hidden">
        @if (result() && result().item.images[0] && result().item.images[0].image; as image) {
            <img
                [image]="image"
                [width]="128"
                [height]="128"
                alt="item().name"
                class="w-full h-full object-cover"
            />
        }
    </div>
</td>
<td class="text-xs" colspan="2" [ngClass]="{'bg-gray-50': lessRelevant()}">
    <div class="font-semibold">
        {{ result().item.category.name }}
    </div>
    <div class="text-wrap">
        {{ result().item.company.name }}
    </div>
</td>
<td class="text-xs" colspan="2" [ngClass]="{'bg-gray-50': lessRelevant()}">
    <div class="flex items-center justify-between">
        <div class="opacity-65 text-wrap">
            {{ address() }}
        </div>
    </div>
</td>
<td class="text-xs" colspan="1" [ngClass]="{'bg-gray-50': lessRelevant()}" [pTooltip]="tooltip" [tooltipDisabled]="!lessRelevant()">
    <div class="flex items-center justify-between">
        <div class="opacity-65 whitespace-nowrap">
            @if (result().item.status?.isListedAndAvailable) {
                <ng-container i18n="@@component.search-supply-result-table.item-available">
                    Nu beschikbaar
                </ng-container>
            } @else {
                <ng-container i18n="@@component.search-supply-result-table.item-unavailable">
                    Niet beschikbaar
                </ng-container>
            }
        </div>
        <div>
            <ng-icon name="heroChevronRight" size="1.2em"/>
        </div>
    </div>
    <ng-template #tooltip>
        <span class="text-xs leading-0">
            Voldoet niet volledig aan de zoekcriteria
        </span>
    </ng-template>
</td>
