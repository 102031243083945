import { Component, computed, HostListener, input } from '@angular/core';
import { NgIcon } from "@ng-icons/core";
import { SearchResultNodeFragment } from '../../../../../../../graphql/generated';
import { MapService } from "../../../../services/map.service";
import { CompanyAddressComponent } from "../../../../../company/components/company-address/company-address.component";
import { MapMarker } from "../../../../../types/search/map-marker";
import { ImageDirective } from '../../../../../core/directives/image.directive';
import { NgClass } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';

@Component({
    selector: '[app-result-table-item]',
    standalone: true,
    imports: [
        NgIcon,
        CompanyAddressComponent,
        ImageDirective,
        NgClass,
        TooltipModule
    ],
    templateUrl: './supply-result-table-item.component.html',
    styleUrl: './supply-result-table-item.component.scss'
})
export class SupplyResultTableItemComponent {

    result = input.required<SearchResultNodeFragment>();

    lessRelevant = computed(() => {
        return this.result().allFiltersMatched != null && !this.result().allFiltersMatched;
    });

    address = computed(() => {
        if(this.result().item.status?.isListed){
            // Check if listing contains current date
            const start = new Date(this.result().item.listing?.validity[0])
            const end = new Date(this.result().item.listing?.validity[1])

            const date = new Date()

            if(date >= start && date <= end){
                return this.result().item.listing?.location?.properties?.address?.city
                    || this.result().item.listing?.location?.properties?.formattedAddress
            }
        }

        return this.result().item.location?.properties?.address?.city
            || this.result().item.location?.properties?.formattedAddress
    })

    constructor(
        private readonly mapService: MapService,
    ) {}

    @HostListener('mouseenter')
    onMouseEnter() {
        this.marker?.setState('hover');
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        this.marker?.setState('idle');
    }

    @HostListener('click')
    onClick() {
        this.marker?.setState('active');
    }

    // ----------[ Getters ]----------

    private _marker: MapMarker | null = null;
    get marker() {
        if (! this._marker) {
            this._marker = this.mapService.getMarker(this.result().item.id);
        }
        return this._marker;
    }
}
